<template>
  <div class="order_refund" :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="退款详情"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <div class="refund_status">
      <div class="status_text">
        <div class="text_top">
          <div></div>
          <div>
            {{
              details.RefundStatus == 0
                ? "进行中"
                : details.RefundStatus == 1
                ? "已完成"
                : details.RefundStatus == 2
                ? "申请失败"
                : ""
            }}
          </div>
        </div>
        <div class="text_btm">{{ details.CreateTime }}</div>
      </div>
    </div>
    <div class="refund_money">
      <div class="money_top" style="border-bottom: 1px solid #efefef">
        <div>退款总金额</div>
        <div class="money_price">
          <span>￥</span>{{ details.ApplyRefundMoney }}
        </div>
      </div>
      <div class="money_top">
        <div class="money_mode">
          <div></div>
          <div>
            退回{{
              details.RefundMoneyChannel == 0
                ? "普利点"
                : details.RefundMoneyChannel == 1
                ? "支付宝"
                : details.RefundMoneyChannel == 2
                ? "微信"
                : details.RefundMoneyChannel == 3
                ? "银行卡"
                : details.RefundMoneyChannel == 4
                ? "现金"
                : details.RefundMoneyChannel == 5
                ? "换货"
                : ""
            }}
          </div>
        </div>
        <div class="money_price" style="color: #333">
          <span>￥</span
          ><span style="font-size: 1rem">{{ details.ActualRefundMoney }}</span>
        </div>
      </div>
    </div>
    <!-- 商品信息 -->
    <div class="order_info">
      <div class="order_title">退款信息</div>
      <div class="info_detail">
        <img src="../../../assets/ShopImg/t.jpg" alt="" />
        <div class="detail_content">
          <div class="decimalPoint-2">{{ details.GoodsName }}</div>
          <div>
            <span>{{ details.SpecName }}</span>
          </div>
          <div>
            <span>￥{{ details.PayMoney }}</span
            ><span>×{{ details.RefundGoodsCount }}</span>
          </div>
        </div>
      </div>
      <div class="detail_reason" style="margin-top: 1.25rem">
        <span>退款原因</span><span>{{ details.RefundReason }}</span>
      </div>
      <div class="detail_reason">
        <span>退款金额</span><span>￥{{ details.ActualRefundMoney }}</span>
      </div>
      <div class="detail_reason">
        <span>申请时间</span><span>{{ details.CreateTime }}</span>
      </div>
      <div class="detail_reason">
        <span>退款编号</span><span>{{ details.RefundCode }}</span>
        <!-- <span
          class="num_copy"
          v-clipboard:copy="details.RefundCode"
          v-clipboard:success="copySuccess"
          v-clipboard:error="copyError"
          >复制</span
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import { queryApplyRefundDetail } from "@/api/shop";
export default {
  data() {
    return {
      route: {},
      details: {},
      isWxApplets: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "退款详情";
      this.isWxApplets = false;
    }
    this.route = this.$route.query;
  },
  mounted() {
    this.init();
  },
  methods: {
    copySuccess(e) {
      console.log("copy arguments e:", e);
      this.$message.success("复制失败");
    },
    copyError(e) {
      console.log("copy arguments e:", e);
      this.$message.error("复制失败");
    },

    async init() {
      let parm = "?id=" + this.route.id;
      const res = await queryApplyRefundDetail(parm);
      this.details = res.response;
    },
    //前去退款详情
    gotorefundDetail() {
      this.$router.push({
        path: "/shop/orders/refundDetail",
      });
    },
    onClickLeft() {
      window.history.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-ellipsis {
  color: #333333;
  font-size: 1rem;
  font-weight: 700;
}
/deep/ .van-nav-bar .van-icon {
  color: #333333;
}
//导航栏下边框
/deep/ .van-hairline--bottom::after {
  border: 0;
}
.order_refund {
  display: flex;
  align-items: center;
  flex-direction: column;
  .refund_status {
    width: 100%;
    height: 6.5rem;
    background: linear-gradient(to right, #fb6d33, #f54224);
    box-sizing: border-box;
    padding: 0.75rem 0.9375rem;
    .status_text {
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 0.625rem;
      box-sizing: border-box;
      padding: 0.75rem 0 0.75rem 0.9375rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .text_top {
        display: flex;
        align-items: center;
        div {
          font-size: 0.9375rem;
          color: #333333;
          font-weight: 700;
        }
        div:first-child {
          width: 0.875rem;
          height: 0.875rem;
          border-radius: 50%;
          background: #f95e2e;
          margin-right: 0.25rem;
        }
      }
      .text_btm {
        font-size: 0.625rem;
        color: #999999;
      }
    }
  }
  .refund_money {
    width: 100%;
    height: 6.25rem;
    background: #fff;
    box-sizing: border-box;
    padding: 0 0.9375rem;
    margin-top: 0.625rem;
    .money_top {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.9375rem;
      color: #fe4a4a;
      .money_price {
        font-size: 1.125rem;
        font-weight: 700;
        span:first-child {
          font-size: 0.625rem;
          font-weight: 400;
        }
      }
      .money_mode {
        font-size: 0.8125rem;
        color: #333;
        display: flex;
        align-items: center;
        div:first-child {
          width: 0.375rem;
          height: 0.375rem;
          background: #d6d6d6;
          border-radius: 50%;
          margin-right: 0.5rem;
        }
      }
    }
  }
  .order_info {
    background: #fff;
    width: 100%;
    // height: 12.6rem;
    box-sizing: border-box;
    padding: 0.9375rem;
    position: relative;
    border-radius: 0.375rem;
    margin-top: 0.625rem;
    .order_title {
      font-size: 0.875rem;
      color: #333333;
      padding-bottom: 0.9375rem;
      font-weight: 700;
    }
    .info_detail {
      display: flex;
      img {
        width: 5.5625rem;
        height: 5.5rem;
        border-radius: 0.25rem;
        margin-right: 0.9375rem;
      }
      .detail_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div:first-child {
          font-size: 0.875rem;
          color: #333333;
          font-weight: 700;
        }
        div:nth-child(2) {
          font-size: 0.75rem;
          color: #858585;
          display: flex;
          justify-content: space-between;
        }
        div:nth-child(3) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.75rem;
          color: #fe4a4a;
          span {
            color: #fe4a4a;
            font-size: 0.9375rem;
            font-weight: 700;
          }
          span:nth-child(2) {
            font-size: 0.75rem;
            color: #858585;
            font-weight: 400;
          }
        }
      }
    }
    .detail_reason {
      font-size: 0.8125rem;
      color: #333;
      margin-top: 0.625rem;
      position: relative;
      span:first-child {
        color: #cbcaca;
        margin-right: 3.375rem;
      }
      .num_copy {
        position: absolute;
        right: 0;
        color: #f95e2e;
      }
    }
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
